import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from '../../api';
import Moment from 'moment';
import MUIDataTable from "mui-datatables";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./occupy.scss";
import Navbar from '../Navbar/Navbar';
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";

const Occupy = (props) =>{
    const [rest, setRest] = useState([]);
    const [loader, setLoader]= useState(true);
    const columns = ["Name", "Date", "Train From","Train No","Train Time On", "Train Time Off", "Room No"];
    const options = {
        filterType: "dropdown",
        selectableRows: false,
        responsive: "",
        viewColumns: false,
    }
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        axios({
          url: baseURL+"/fetch-website-occupy-request",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
          setRest(res.data.bedrequest);
          setLoader(false)
        });
    }, []);

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <section className={`occupy wpo-contact-area section-padding`} id="occupy" >
                <div className="wpo-wpo-contact-form-map">
                    <div className="container crardsview">
                        <div className="row mt-4">
                            <div className="co-lg-12   col-md-12">
                            {loader && (
                                <CircularProgress
                                disableShrink
                                style={{
                                    marginLeft: "600px",
                                    marginTop: "200px",
                                    marginBottom: "200px",
                                }}
                                />
                            )}
                            {!loader && (
                    <MUIDataTable
                        title={"Occupy List"}
                        data={rest.map(item => {
                            return [
                                item.name,
                                Moment(item.br_request_date).format('DD-MM-YYYY'),
                                item.br_train_from,
                                item.br_train_no,
                                item.br_train_time_on,
                                item.br_train_time,
                                item.br_room_no,
                                item.id
                            ]
                        })}
                        columns={columns}
                        options={options}
  
                    />
                    
                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Scrollbar/>
            <Footer />
        </>
    )
}
export default Occupy;