import React, { useEffect,useState } from 'react';
import Scrollbar from '../../scrollbar/scrollbar';
import 'react-toastify/dist/ReactToastify.css';
import "./occupancyreportform.scss";
import { baseURL } from '../../../api';
import Footer from "../../footer/Footer";
import Navbar from '../../Navbar/Navbar';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Moment from 'moment';

const OccupancyReportForm = (props) =>{
    const navigate = useNavigate();
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    
    const [forms, setForms] = useState({
        from_date: '',
        to_date: date,
        status:'All'
    });

    const [isChecked, setIsChecked] = useState(false);

    const onInputChange = (e) => {
        
            setForms({
                ...forms,
                [e.target.name]: e.target.value,
            });
        
    };

    const onSubmit = (e) => {
        navigate('/occupancy-report?from_date='+forms.from_date+'&to_date='+forms.to_date+'&status='+forms.status);
    };

    const onCurrentMonthView = (e) => {
       
        var firstDay = Moment().startOf('month').format('YYYY-MM-DD');
        var lastDay = Moment().endOf('month').format("YYYY-MM-DD");
        navigate('/occupancy-report?from_date='+firstDay+'&to_date='+lastDay+'&status='+forms.status);
    };

    const onCurrentMonthDownload = (e) => {
        var firstDay = Moment().startOf('month').format('YYYY-MM-DD');
        var lastDay = Moment().endOf('month').format("YYYY-MM-DD");
        e.preventDefault();
        let data = {
            from_date: firstDay,
            to_date: lastDay,
            status:'All',
        };

        axios({
            url: baseURL+"/download-occupancy-report",
            method: "POST",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            
           const url = window.URL.createObjectURL(new Blob([res.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'occupancy_report.csv');
           document.body.appendChild(link);
           link.click();
           toast.success('Report is download Sucessfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
             
        }).catch((err) =>{
            toast.error('Failed to download', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
           
        });
    };
    
    const onLastMonthView = (e) => {
       
        var firstDay = Moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        var lastDay = Moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        navigate('/occupancy-report?from_date='+firstDay+'&to_date='+lastDay+'&status='+forms.status);
    };

    const onLastMonthDownload = (e) => {
        var firstDay = Moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        var lastDay = Moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        e.preventDefault();
        let data = {
            from_date: firstDay,
            to_date: lastDay,
            status:'All',
        };

        axios({
            url: baseURL+"/download-occupancy-report",
            method: "POST",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            
           const url = window.URL.createObjectURL(new Blob([res.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'occupancy_report.csv');
           document.body.appendChild(link);
           link.click();
           toast.success('Report is download Sucessfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
             
        }).catch((err) =>{
            toast.error('Failed to download', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
           
        });
    };

    const onDownload = (e) =>{
        e.preventDefault();
        let data = {
            from_date: forms.from_date,
            to_date: forms.to_date,
            status:forms.status
        };
        console.log("data : ",data);
        axios({
            url: baseURL+"/download-occupancy-report",
            method: "POST",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            
           const url = window.URL.createObjectURL(new Blob([res.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'occupancy_report.csv');
           document.body.appendChild(link);
           link.click();
           toast.success('Report is download Sucessfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
             
        }).catch((err) =>{
            toast.error('Failed to download', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
           
        });
    };

    const [formstatus, setFormStatus] = useState([]);
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        
        axios({
          url: baseURL+"/fetch-website-status",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
            setFormStatus(res.data.forstatus);
          
        });
    }, []);
    
    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`occupancyReportForm wpo-contact-area section-padding ${props.contactclass}`} id="cheangepassword">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="row mt-4">
                        <div className="co-lg-12   col-12 mt-4">
                            <div className="row mt-4">
                                <div className="col col-lg-12 col-md-12 col-12">
                                    <div className="wpo-contact-form">
                                    <h2>Occupancy Report</h2>
                                        <form id="addrrms"> 
                                        {!isChecked  && (<div className="submit-area">
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <button type="submit" className="theme-btns mobiledownloadbtn" onClick={(e) => onCurrentMonthView(e)}>
                                                            Current Month View
                                                        </button>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <button type="submit" className="theme-btns mobiledownloadbtn" onClick={(e) => onCurrentMonthDownload(e)}>
                                                            Current Month Download
                                                        </button>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <button type="submit" className="theme-btns mobiledownloadbtn" onClick={(e) => onLastMonthView(e)}>
                                                            Last Month View
                                                        </button>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <button type="submit" className="theme-btns mobiledownloadbtn" onClick={(e) => onLastMonthDownload(e)}>
                                                            Last Month Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                            <div className='row'>
                                                <div className="col col-lg-4 col-12" style={{display:'flex'}}>
                                                    <input
                                                        style={{color:'Black',height:'20px',width:'30%'}}
                                                        checked={isChecked}
                                                        type="checkbox"
                                                        name="otherfilter"
                                                        onChange={() => setIsChecked((prev) => !prev)}
                                                    />
                                                    <p style={{color:'black'}}>Other Filters</p>
                                                </div>
                                            </div>
                                                
                                            {isChecked  && (
                                                <>
                                            <div className='row'>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>From Date <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.from_date}
                                                            type="date"
                                                            name="from_date"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>To Date <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.to_date}
                                                            type="date"
                                                            name="to_date"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Status</label>
                                                        <select name="status" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Status</option>
                                                            {formstatus.map((hd) => (
                                                                <option key={hd.status} value={hd.status}>
                                                                    {hd.status}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)} style={{marginRight:'20px'}}>
                                                    View
                                                </button>
                                                <button type="submit" className="theme-btn" onClick={(e) => onDownload(e)}>
                                                    Download
                                                </button>
                                            </div>
                                            </>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
            <Scrollbar/>
        </>
    )
}
export default OccupancyReportForm;