import React, { Component } from 'react';
import "./login.scss";
import { baseURL } from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends Component {
    state = {
        mobile: "",
        password: "",
        login: false,
        token: null,
        errorShown:false,
    };

    onUserLogin() {
        if (this.state.mobile !== "" && this.state.password !== "") {
          
            let formData = new FormData();
    
            formData.append('mobile', this.state.mobile);
            formData.append('password', this.state.password);
    
    
            fetch(
                encodeURI(baseURL+'/website-login'),
                {
                    method: "POST",
                    body:formData
                }
            )
            .then((response) => response.json())
            .then((data) => {
              console.log("login", data);
              console.log("logintype", data.UserInfo.user.user_type_id);
              localStorage.setItem("id", data.UserInfo.user.user_type_id);
              localStorage.setItem("name", data.UserInfo.user.name);
              localStorage.setItem("user_type_id", data.UserInfo.user.user_type_id);
              localStorage.setItem("head_quarter", data.UserInfo.user.head_quarter);
    
                if (data.UserInfo.token) {
                    localStorage.setItem("login", data.UserInfo.token);
                    if(data.UserInfo.user.user_type_id == 3){
                        window.location = "/dashboard";
                    }else if(data.UserInfo.user.user_type_id == 2){
                        window.location = "/dashboard";
                    }
                    
                    this.setState({
                        login: true,
                        token: data.UserInfo.token,
                    });
                }
              
                if(JSON.stringify(data).includes("Unauthorised")) {
                    toast.error('Username or password incorrect', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    this.setState({
                        errorShown: true,
                    });
                }
            })
            .catch((err) => {
    
             
                if(!this.state.errorShown){
                    toast.error('Username or password incorrect', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    this.setState({
                        errorShown: true,
                    });
                }
            });
        }else {
            if(!this.state.errorShown){
                toast.error('Please enter Username or Password', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                this.setState({
                    errorShown: true,
                });
            }
        }
    }

    onUserSignUp() {
        this.props.history.push("/login");
    }

    onRequest(){
        window.location = "/request";
    }

    render() {
        let x = document.getElementById("pwd");
        if (x) {
          
            x.addEventListener("keyup", function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    document.getElementById("signin").click();
                }
            });
        }
        if (this.state.login) 
        this.props.history.push("/dashboard");
        
         const { mobile, password } = this.state;

         

        return(
            <>
                <section className={`formlogin wpo-contact-area section-padding`} id="login" > 
                    <div className="wpo-wpo-contact-form-map">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="wpo-section-title">
                                        <h2 className='mobile_heading'>Login</h2>
                                    </div>
                                    <div className='topButton'>
                                        <button type="button" className="theme-btn" onClick={() => this.onRequest()}>
                                            Request
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row cards">
                                <div className="co-lg-6   col-md-6">
                                    <div className="row">
                                        <div className="col col-lg-12 col-md-12 col-12">
                                            <div className="wpo-contact-form">
                                                <form id="addrrms"> 
                                                    <div className='row'>
                                                        <div className="col col-lg-12 col-12">
                                                            <div className="form-field">
                                                                <label className='label'>Mobile No <span>*</span></label>
                                                                <input
                                                                    style={{color:'Black'}}
                                                                    required
                                                                    value={mobile}
                                                                    type="text"
                                                                    name="mobile"
                                                                    onChange={(event) =>
                                                                        this.setState({ mobile: event.target.value })
                                                                    }
                                                                    
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col col-lg-12 col-12">
                                                            <div className="form-field">
                                                                <label className='label'>Password<span>*</span></label>
                                                                <input
                                                                    style={{color:'Black'}}
                                                                    required
                                                                    value={password}
                                                                    type="password"
                                                                    name="password"
                                                                    onChange={(event) =>
                                                                        this.setState({ password: event.target.value })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="submit-area">
                                                        <button type="button" className="theme-btn" onClick={() => this.onUserLogin()} >
                                                            Login
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default Login;