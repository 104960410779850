import React, { useEffect, useState } from "react";
import "./approved.scss";
import Navbar from '../Navbar/Navbar';
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { baseURL } from '../../api';
import Moment from 'moment';
import Footer from "../footer/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Scrollbar from "../scrollbar/scrollbar";

const Approved = (props) =>{
    
    const [bedrequests, setBedRequest] = useState([]);
    const [modalvalue, setModalValue] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
    const [loader, setLoader]= useState(true);
    const [forms, setForms] = useState({
        br_room_no: '',
        br_status: ''
    });
    const columns = ["Name", "Date", "Train From", "Train No", "Train Time On","Train Time Off", "Remarks",
    {
        name: localStorage.getItem("user_type_id") == 2 ? "Action" : "",
        options: {
          filter: false,
          print:false,
          download:false,
          customBodyRender: (value) =>{
            return (
                <Tooltip title={"Assign"}>
                    <IconButton onClick={() =>
                        {
                            setModalValue(value);
                            openModal()
                        }
                    } 
                    style={{
                      display: localStorage.getItem("user_type_id") == 2 ? "" : "none",
                    }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            );
        }
        },
      },
];
    const options = {
        filterType: "dropdown",
        selectableRows: false,
        responsive: "",
        viewColumns: false,
        
    }

    const closeModal = () => {
        setShowModal(false);
      };

    const openModal = (e) =>{
        setShowModal(true);
    }

    const updateRoom = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true)
        let data = {
          
          br_room_no: forms.br_room_no,
          br_status: 'Assign'
          
        };
    
        axios({
          url:
          baseURL+"/update-website-bed-request-status/" + modalvalue,
          method: "PUT",
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        })
          .then((res) => {
            setBedRequest(res.data.bedrequest);
            toast.success('Data Updated Sucessfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsButtonDisabled(false)
            closeModal();
          })
          .catch((error) => {
            toast.error('Failed to Update', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsButtonDisabled(false)
            closeModal();
          });
     };

     const onInputChange = (e) => {
        setForms({
        ...forms,
        [e.target.name]: e.target.value,
        });
        
    };

    const [roomsno, setRoomsNo] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
            headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
        };     
        fetch(baseURL+'/fetch-website-rooms-available', requestOptions)
        .then(response => response.json())
        .then(data => setRoomsNo(data.roomsno)); 
    }, []);

    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        axios({
          url: baseURL+"/fetch-website-approved-request",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
          setBedRequest(res.data.bedrequest);
          setLoader(false)
        });
    }, []);
    return (
        <>
          
          <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
          <section className={`pending wpo-contact-area section-padding`} id="pending" >
            <div className="wpo-wpo-contact-form-map">
              <div className="container crardsview">
                <div className="row  mt-4">
                  <div className="co-lg-12   col-md-12">
                  {loader && (
                    <CircularProgress
                      disableShrink
                      style={{
                        marginLeft: "600px",
                        marginTop: "200px",
                        marginBottom: "200px",
                      }}
                    />
                  )}
                  {!loader && (
                    <MUIDataTable
                        title={"Approved List"}
                        data={bedrequests.map(item => {
                            return [
                                item.name,
                                Moment(item.br_request_date).format('DD-MM-YYYY'),
                                item.br_train_from,
                                item.br_train_no,
                                item.br_train_time_on,
                                item.br_train_time,
                                item.br_remarks,
                                item.id
                            ]
                        })}
                        columns={columns}
                        options={options}
                    />
                    
                    )}

                    <Modal isOpen={showModal} toggle={() => closeModal()} style={{marginTop:'90px'}}>
                        <ModalHeader toggle={() => closeModal()}>Assign Room</ModalHeader>
                        <ModalBody>
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="col col-lg-12 col-12">
                                        <div className="form-field">
                                            <label className='label'>Running Room<span>*</span></label>
                                            <select name="br_room_no" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                <option selected disabled>Select Running Room</option>
                                                {roomsno.map((hq) => (
                                                    <option key={hq.rooms} value={hq.rooms}>
                                                        {hq.rooms}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <ModalFooter>
                                    <div className="submit-area">
                                        <button type="submit" className="theme-btn" onClick={(e) => updateRoom(e)} disabled={isButtonDisabled}>
                                            Assign Room
                                        </button>
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalBody>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            
          </section>
          <Scrollbar/>
          <Footer />
  
        </>
      )
}
export default Approved;