import React, { useEffect,useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./register.scss";
import { baseURL } from '../../api';
import { useNavigate } from "react-router-dom";

const Register = (props) =>{
    
    const [forms, setForms] = useState({
        name: '',
        gender: '',
        email: '',
        mobile: '',
        cms_id: '',
        division: '',
        head_quarter: '',
        category: '',
        br_train_no: '',
        br_train_from: '',
        br_room_division: '',
        br_train_time_on: ''
    });

    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const validateOnlyDigits = (inputtxt) => {

        
         var phoneno = /^\d+$/;
         if(inputtxt.match(phoneno) || inputtxt.length===0){
             return true;
               }
             else
               {
               
               return false;
               }
       }

    const onInputChange = (e) => {

        if(e.target.name==="mobile"){
    
            if(validateOnlyDigits(e.target.value)){
                setForms({
                ...forms,
                [e.target.name]: e.target.value,
                });
            }
            
        }else{
    
            setForms({
            ...forms,
            [e.target.name]: e.target.value,
            });
        }
    };

    const onSubmit = (e) => {
        let data = {
            name: forms.name,
            gender: forms.gender,
            email: forms.email,
            mobile: forms.mobile,
            cms_id: forms.cms_id,
            division: forms.division,
            head_quarter: forms.head_quarter,
            category: forms.category,
            br_train_no: forms.br_train_no,
            br_train_from: forms.br_train_from,
            br_room_division: forms.br_room_division,
            br_train_time_on: forms.br_train_time_on
        };
        var v = document.getElementById("addrrms").checkValidity();
       
        e.preventDefault();
        if (v) {
            setIsButtonDisabled(true)
            axios({
              url: baseURL+"/create-website-user",
              method: "POST",
              data,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
              },
            }).then((res) => {
              
              if(res.data.code === 200){
                toast.success('Data Inserted Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                setForms({
                    name: '',
                    gender: '',
                    email: '',
                    mobile: '',
                    cms_id: '',
                    division: '',
                    head_quarter: '',
                    category: '',
                    br_train_no: '',
                    br_train_from: ''
                });
              }else{
                toast.error('Data is not Inserted Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                setForms({
                    name: '',
                    gender: '',
                    email: '',
                    mobile: '',
                    cms_id: '',
                    division: '',
                    head_quarter: '',
                    category: '',
                    br_train_no: '',
                    br_train_from: ''
                });
              }
            });
          }
    };

    const [headdivision, setHeadDivision] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
        };     
        fetch(baseURL+'/fetch-website-head-division', requestOptions)
        .then(response => response.json())
        .then(data => setHeadDivision(data.headdivision)); 
    }, []);

    const [headquarter, setHeadQuarter] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
        };     
        fetch(baseURL+'/fetch-website-head-quarter/'+forms.division, requestOptions)
        .then(response => response.json())
        .then(data => setHeadQuarter(data.headquarter)); 
    }, [forms.division]);

    const [categorylist, setCategoryList] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
        };     
        fetch(baseURL+'/fetch-website-category', requestOptions)
        .then(response => response.json())
        .then(data => setCategoryList(data.category)); 
    }, []);

    let navigate = useNavigate(); 
    const onLogin = (e) =>{
        window.location = "/login";
    };

    return(
        <>
            
            <section className={`formregister wpo-contact-area section-padding ${props.contactclass}`} id="register">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="wpo-section-title" style={{marginBottom:'0px'}}>
                                <h2 className='mobile_heading'>Registration</h2>
                            </div>
                            <div className='topButton'>
                                <button type="button" className="theme-btn" onClick={(e) => onLogin(e)} disabled={isButtonDisabled}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="co-lg-12   col-12">
                            <div className="row">
                                <div className="col col-lg-12 col-md-12 col-12">
                                    <div className="wpo-contact-form" style={{marginBottom:'0px'}} >
                                        <form id="addrrms"> 
                                            <div className='row'>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Full Name <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.name}
                                                            type="text"
                                                            name="name"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Gender <span>*</span></label>
                                                        <select name="gender" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Email <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.email}
                                                            type="email"
                                                            name="email"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Mobile No <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.mobile}
                                                            inputProps={{ maxLength: 10 }}
                                                            type="text"
                                                            name="mobile"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>CMS ID<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.cms_id}
                                                            type="text"
                                                            name="cms_id"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Division<span>*</span></label>
                                                        <select name="division" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Head Division</option>
                                                            {headdivision.map((hd) => (
                                                                <option key={hd.head_division} value={hd.head_division}>
                                                                    {hd.head_division}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Head Quarter<span>*</span></label>
                                                        <select name="head_quarter" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Head Quarter</option>
                                                            {headquarter.map((hq) => (
                                                                <option key={hq.id} value={hq.head_quarter}>
                                                                    {hq.head_quarter}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Category<span>*</span></label>
                                                        <select name="category" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Category</option>
                                                            {categorylist.map((option) => (
                                                                <option key={option.id} value={option.category}>
                                                                    {option.category}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Running Room Division<span>*</span></label>
                                                        <select name="br_room_division" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Running Room Division</option>
                                                            {headquarter.map((hqr) => (
                                                                <option key={hqr.id} value={hqr.head_quarter}>
                                                                    {hqr.head_quarter}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Train From<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.br_train_from}
                                                            type="text"
                                                            name="br_train_from"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-2 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Train No<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.br_train_no}
                                                            type="text"
                                                            name="br_train_no"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-2 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Time On<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            value={forms.br_train_time_on}
                                                            required
                                                            type="time"
                                                            name="br_train_time_on"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-2 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Time Off<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.br_train_time}
                                                            type="time"
                                                            name="br_train_time"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area" style={{marginTop:'0px',marginBottom:'12px'}}>
                                                <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)} disabled={isButtonDisabled}>
                                                    Submit Now
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
            
        </>
    )
}
export default Register;