import React, { useEffect, useState } from "react";
import "./useres.scss";
import Navbar from '../Navbar/Navbar';
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { baseURL } from '../../api';
import Footer from "../footer/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../scrollbar/scrollbar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Useres = (props) =>{

    const navigate = useNavigate();
    const [users, setUser] = useState([]);
    const [loader, setLoader]= useState(true);
    
    const columns = ["Name", "Gender", "Email", "Mobile", "CMS", "Division","Category","User Type","Status"];
    const options = {
        filterType: "dropdown",
        selectableRows: true,
        responsive: "",
        viewColumns: false,
        selectToolbarPlacement:"above",
        selectableRowsOnClick: true,
        onRowsSelect : (currentRowSelected, allRowsSelected ) => {
            var tempvalue = allRowsSelected.map(row => row.dataIndex);
            var new_id=[];
            for(var i=0;i<tempvalue.length;i++)
            {                
                new_id.push(users[tempvalue[i]]["id"]);              
            }
            localStorage.setItem("selectedUserIds", new_id+'');
        },
        customToolbarSelect: () => {},
        
    }

    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        axios({
          url: baseURL+"/fetch-website-user",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
          setUser(res.data.use);
          setLoader(false)
        });
    }, []);

    const onSubmit = (e) => {
        var userIdsSelected =  localStorage.getItem("selectedUserIds");

        let data = {
            user_id: userIdsSelected,
            user_status: "Inactive",
        };

        axios({
            url: baseURL+"/update-website-user",
            method: "PUT",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            toast.success('Data is successfully Updated', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            navigate('/useres');
            setUser(res.data.use);
          });
    };

    const onReject = (e) => {
        var userIdsSelected =  localStorage.getItem("selectedUserIds");

        let data = {
            user_id: userIdsSelected,
            user_status: "Active",
        };

        axios({
            url: baseURL+"/update-website-user",
            method: "PUT",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            toast.success('Data is successfully Updated', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            navigate('/useres');
            setUser(res.data.use);
          });
    };

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
          <section className={`useres wpo-contact-area section-padding`} id="useres" >
            <div className="wpo-wpo-contact-form-map">
              <div className="container crardsview">
                <div className="row  mt-4">
                  <div className="co-lg-12   col-md-12">
                  {loader && (
                    <CircularProgress
                      disableShrink
                      style={{
                        marginLeft: "600px",
                        marginTop: "200px",
                        marginBottom: "200px",
                      }}
                    />
                  )}
                  {!loader && (
                    <MUIDataTable
                        title={"Useres List"}
                        data={users.map(item => {
                          
                            return [
                                item.name,
                                item.gender,
                                item.email,
                                item.mobile,
                                item.cms_id,
                                item.division,
                                item.category,
                                item.user_type_id == '2' ? 'Crew Taker' : 'User',
                                item.user_status,
                                item.id
                            ]
                        })}
                        columns={columns}
                        options={options}
  
                    />
                    
                    )}
                    <div className="submit-area">
                        <button type="submit" className="theme-btn" style={{marginRight:'10px'}} onClick={(e) => onSubmit(e)}>
                            Inactive
                        </button>
                        <button type="submit" className="theme-btn" onClick={(e) => onReject(e)}>
                            Active
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </section>
          <Scrollbar/>
          <Footer />
        </>
    )
}
export default Useres;