import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './style.css';

const logoutUser = () => {
    localStorage.removeItem('head_quarter');
    localStorage.removeItem('login');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('user_type_id');
    window.location = "/login";
}
var user_type_id = localStorage.getItem("user_type_id");
const MobileMenu = () => {

    const [menuActive, setMenuState] = useState(false);
    
    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>
                <ul className="responsivemenu">
                    <li><Link to="/dashboard" >Dashboard</Link></li>
                    {   
                        user_type_id == '3' && 
                        <li><Link to="/useres" >Useres</Link></li>
                    }
                    {   
                        user_type_id == '3' && 
                        <li><Link to="/user-add" >Add User</Link></li>
                    }
                    {
                        user_type_id == '3' && 
                        <li><Link to="/pending" >Request</Link></li>
                    }
                    {
                        user_type_id == '2' && 
                        <li><Link to="/approved" >Approved</Link></li>
                                            
                    }
                    <li><Link to="/occupy" >Occupy</Link></li>
                    <li><Link to="/past-request" >Past Request</Link></li>
                    <li><Link to="/feedback" >Feedback</Link></li>
                    <li><Link to="/user-edit" >Edit Profile</Link></li>
                    <li><Link to="/change-password" >Change Password</Link></li>
                    <li><Link onClick={logoutUser}>Logout</Link></li>
                </ul>
            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;