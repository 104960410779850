import React from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Approved from '../../components/Approved/Approved';
import Dashboard from '../../components/Dashboard/Dashboard';
import Feedback from '../../components/Feedback/Feedback';
import Login from '../../components/Login/Login';
import Occupy from '../../components/Occupy/Occupy';
import Pending from '../../components/Pending/Pending';
import Register from '../../components/Register/Register';
import CurrentOccupancy from '../../components/Reports/CurrentOccupancy/CurrentOccupancy';
import OccupancyReportForm from '../../components/Reports/OccupancyReport/OccupancyReportForm';
import OccupancyReport from '../../components/Reports/OccupancyReport/OccupancyReport';
import Rest from '../../components/Rest/Rest';
import ChangePassword from '../../components/Useres/ChangePassword';
import EditProfile from '../../components/Useres/EditProfile';
import UserAdd from '../../components/Useres/UserAdd';
import Useres from '../../components/Useres/Useres';

const AllRoute = () => { 

  return (
    <div className="App">
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/request' element={<Register/>}/>
            <Route path='/' element={<Login/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/dashboard' element={<Dashboard/>}/>
            <Route path='/feedback' element={<Feedback/>}/>
            <Route path='/pending' element={<Pending/>}/>
            <Route path='/approved' element={<Approved/>}/>
            <Route path='/occupy' element={<Occupy/>}/>
            <Route path='/past-request' element={<Rest/>}/>
            <Route path='/current-occupancy' element={<CurrentOccupancy/>}/>
            <Route path='/occupancy-form' element={<OccupancyReportForm/>}/>
            <Route path='/occupancy-report' element={<OccupancyReport/>}/>
            <Route path='/useres' element={<Useres/>}/>
            <Route path='/user-add' element={<UserAdd/>}/>
            <Route path='/user-edit' element={<EditProfile/>}/>
            <Route path='/change-password' element={<ChangePassword/>}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default AllRoute;
