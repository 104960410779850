import React, { useEffect, useState } from "react";
import "./pending.scss";
import Navbar from '../Navbar/Navbar';
import TrainIcon from '@mui/icons-material/Train';
import Card from '@mui/joy/Card';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/joy/IconButton';
import Chip from '@mui/joy/Chip';
import axios from "axios";
import { baseURL } from '../../api';
import Moment from 'moment';
import Footer from "../footer/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Scrollbar from "../scrollbar/scrollbar";

const Pending = (props) =>{
    
    const [bedrequests, setBedRequest] = useState([]);
    const [loader, setLoader]= useState(true);
    
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        axios({
          url: baseURL+"/fetch-website-request",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
          setBedRequest(res.data.bedrequest);
          setLoader(false)
        });
    }, []);

    const onSubmit = (e) => {
        var bedrequestIdsSelected =  e;

        let data = {
            bedrequestid: bedrequestIdsSelected,
            br_status: "Approved",
        };

        axios({
            url: baseURL+"/update-website-bed-request-pending",
            method: "PUT",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            toast.success('Data is successfully Updated', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            
            setBedRequest(res.data.bedrequest);
          });
    };

    const onReject = (e) => {
        var bedrequestIdsSelected =  e;

        let data = {
            bedrequestid: bedrequestIdsSelected,
            br_status: "Reject",
        };

        axios({
            url: baseURL+"/update-website-bed-request-pending",
            method: "PUT",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          }).then((res) => {
            toast.success('Data is successfully Updated', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            
            setBedRequest(res.data.bedrequest);
          });
    };

    return (
        <>
          
          <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
          <section className={`pending wpo-contact-area section-padding`} id="pending" >
            <div className="wpo-wpo-contact-form-map">
              <div className="container crardsview">
                <div className="row  mt-4">
                  
                  {loader && (
                    <CircularProgress
                      disableShrink
                      style={{
                        marginLeft: "600px",
                        marginTop: "200px",
                        marginBottom: "200px",
                      }}
                    />
                  )}
                  {!loader && (
                    <>
                    {bedrequests.length <= 0 &&(
                        <>
                            <h1 style={{textAlign:'center'}}>No data available</h1>
                        </>
                    )}
                    {bedrequests.length > 0 &&(
                        <>
                    {bedrequests.map((item) => (
                       
                        <div class="col-md-4 mt-4">
                            <Card
                                variant="outlined"
                                orientation="horizontal"
                                style={{height:'180px'}}
                            >
                                <div className="row col-12">
                                    <div className="row">
                                        <div className="col-md-6 col-6">
                                            <AccountCircleIcon style={{fontSize:'20px', color:'black'}}/>
                                            <span className="card_name">
                                                {item.name} - 
                                                <span className="card_headquarter">
                                                    {item.head_quarter}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-md-6 col-6" style={{justifyContent:'end', display:'flex',alignItems:'center'}}>
                                            <span className="card_train_name">
                                                <TrainIcon style={{fontSize:'20px', color:'black'}}/>
                                                {item.br_train_from} - {item.br_train_no}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-6">
                                            <span className="card_train_time">
                                                <AccessTimeFilledIcon style={{fontSize:'20px', color:'black'}}/>
                                                {item.br_train_time_on} - {item.br_train_time}
                                            </span>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <span className="card_train_date">
                                                <CalendarMonthIcon style={{fontSize:'20px', color:'black'}}/>
                                                {Moment(item.br_request_date).format('ll')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-6">
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                size="sm"
                                                sx={{ pointerEvents: 'none' }}
                                            >
                                                {item.br_status == "Pending" && (
                                                    <span className="card_train_status">
                                                        Waiting to Approve
                                                    </span>
                                                )}

                                                {item.br_status == "Approved" && (
                                                    <span className="card_train_status">
                                                        Waiting to Assign Room
                                                    </span>
                                                )}
                                                
                                            </Chip>
                                        </div>
                                        {item.br_status == "Pending" && (
                                          <div className="col-md-6 col-6" style={{display:'flex', justifyContent:'space-evenly'}}>
                                              <IconButton
                                                  onClick={() => onSubmit(item.id)}
                                                  aria-label="Like minimal photography"
                                                  size="md"
                                                  variant="solid"
                                                  color="danger"
                                                  sx={{
                                                      background:'green',
                                                      color:'white',
                                                      borderRadius:'50%',
                                                      height:'20px',
                                                      width:'20px',
                                                      '&:hover': { boxShadow: 'md', background: 'green', boxShadow:'8px 8px 16px 0px black' },
                                                  }}
                                                  >
                                                  <CheckCircleOutlineIcon />
                                              </IconButton>
                                              <IconButton
                                                  onClick={(e) => onReject(item.id)}
                                                  aria-label="Like minimal photography"
                                                  size="md"
                                                  variant="solid"
                                                  color="danger"
                                                  sx={{
                                                      background:'red',
                                                      color:'white',
                                                      borderRadius:'50%',
                                                      height:'20px',
                                                      width:'20px',
                                                      '&:hover': { boxShadow: 'md', background: 'red', boxShadow:'8px 8px 16px 0px black' },
                                                  }}
                                                  >
                                                  <CancelPresentationIcon />
                                              </IconButton>
                                          </div>
                                        )}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}
                    </>
                    )}
                    </>
                    
                    )}
                    
                  
                </div>
              </div>
            </div>
            
          </section>
          <Scrollbar/>
          <Footer />
  
        </>
      )
}
export default Pending;