import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from '../../api';
import Moment from 'moment';
import MUIDataTable from "mui-datatables";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./feedback.scss";
import Navbar from '../Navbar/Navbar';
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";

const Feedback = (props) =>{
    const [feedbacks, setFeedbacks] = useState([]);
    const [loader, setLoader]= useState(true);
    const columns = ["Name", "Date", "Room No","Room", "Food", "Staff", "Feedback"];
    const options = {
        filterType: "dropdown",
        selectableRows: false,
        responsive: "",
        viewColumns: false,
    }
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        axios({
          url: baseURL+"/fetch-website-feedback",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
          setFeedbacks(res.data.feedback);
          setLoader(false)
        });
    }, []);

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <section className={`feedback wpo-contact-area section-padding`} id="feedback" >
                <div className="wpo-wpo-contact-form-map">
                    <div className="container crardsview">
                        <div className="row mt-4">
                            <div className="co-lg-12   col-md-12">
                            {loader && (
                                <CircularProgress
                                disableShrink
                                style={{
                                    marginLeft: "600px",
                                    marginTop: "200px",
                                    marginBottom: "200px",
                                }}
                                />
                            )}
                            {!loader && (
                    <MUIDataTable
                        title={"Feedback List"}
                        data={feedbacks.map(item => {
                            return [
                                item.name,
                                Moment(item.feedback_date).format('DD-MM-YYYY'),
                                item.br_room_no,
                                item.feedback_room,
                                item.feedback_food,
                                item.feedback_staff,
                                item.feedback,
                                item.id
                            ]
                        })}
                        columns={columns}
                        options={options}
  
                    />
                    
                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Scrollbar/>
            <Footer />
        </>
    )
}
export default Feedback;