import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dashboard.scss";
import { baseURL } from '../../api';
import Navbar from '../Navbar/Navbar';
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

const Dashboard = (props) =>{
    const [roomsNo, setRoomsNo] = useState({});
    const [loader, setLoader]= useState(true);
    
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        }
        
        axios({
          url: baseURL+"/fetch-website-rooms-all-available",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
            setRoomsNo(res.data.roomsno);
            setLoader(false)
          
        });
    }, []);
    

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`dashboard wpo-contact-area section-padding`} id="dashboard">
                <div className="wpo-wpo-contact-form-map">
                    <div className="container">
                        <div className="row mt-4">
                            <div className="co-lg-12  col-md-12">
                                <div className="row mt-4">
                                    <div className="col col-lg-12 col-md-12 col-12 mt-4">
                                        <div className="wpo-contact-form mt-4">
                                            <div className="row">
                                                {loader && (
                                                    <CircularProgress
                                                    disableShrink
                                                    style={{
                                                        marginLeft: "600px",
                                                        marginTop: "200px",
                                                        marginBottom: "200px",
                                                    }}
                                                    />
                                                )}
                                                {!loader && (
                                                    <MDBRow>
                                                        {roomsNo.map((current_data, index) => {
                                                            if (current_data.status_label == 'Assign') {
                                                                return <MDBCol md='2' className="mob" style={{textAlign:'center',background:'red',color:'white',border:'1px solid',padding:'7px'}} >{current_data.rooms} <br/> {current_data.status_label}</MDBCol>;
                                                            }
                                                                return <MDBCol md='2' className="mob" style={{textAlign:'center',background:'green',color:'white',border:'1px solid',padding:'7px'}}>{current_data.rooms} <br/> {current_data.status_label}</MDBCol>;
                                                        })}
                                                    </MDBRow>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Scrollbar/>
            <Footer />
        </>
    )
}
export default Dashboard;