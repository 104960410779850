import React, { useEffect,useState } from 'react';
import axios from "axios";
import Scrollbar from '../scrollbar/scrollbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./useradd.scss";
import { baseURL } from '../../api';
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import Navbar from '../Navbar/Navbar';

const UserAdd = (props) =>{
    
    const [forms, setForms] = useState({
        name: '',
        gender: '',
        email: '',
        mobile: '',
        cms_id: '',
        division: '',
        category: '',
        cpassword: ''
    });

    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const validateOnlyDigits = (inputtxt) => {

        
         var phoneno = /^\d+$/;
         if(inputtxt.match(phoneno) || inputtxt.length===0){
             return true;
               }
             else
               {
               
               return false;
               }
       }

    const onInputChange = (e) => {

        if(e.target.name==="mobile"){
    
            if(validateOnlyDigits(e.target.value)){
                setForms({
                ...forms,
                [e.target.name]: e.target.value,
                });
            }
            
        }else{
    
            setForms({
            ...forms,
            [e.target.name]: e.target.value,
            });
        }
    };

    const onSubmit = (e) => {
        let data = {
            name: forms.name,
            gender: forms.gender,
            email: forms.email,
            mobile: forms.mobile,
            cms_id: forms.cms_id,
            division: forms.division,
            category: forms.category,
            cpassword: forms.cpassword
            
        };
        var v = document.getElementById("addrrms").checkValidity();
       
        e.preventDefault();
        if (v) {
            setIsButtonDisabled(true)
            axios({
              url: baseURL+"/create-website-user-ct",
              method: "POST",
              data,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
              },
            }).then((res) => {
              
              if(res.code === 200){
                toast.success('Data Inserted Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                setForms({
                    name: '',
                    gender: '',
                    email: '',
                    mobile: '',
                    cms_id: '',
                    division: '',
                    category: '',
                    cpassword: ''
                    
                });
              }else{
                toast.error('Data is not Inserted Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                setForms({
                    name: '',
                    gender: '',
                    email: '',
                    mobile: '',
                    cms_id: '',
                    division: '',
                    category: '',
                    cpassword: ''
                    
                });
              }
            });
          }
    };

    const [categorylist, setCategoryList] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
        };     
        fetch(baseURL+'/fetch-website-category', requestOptions)
        .then(response => response.json())
        .then(data => setCategoryList(data.category)); 
    }, []);

    let navigate = useNavigate(); 
    const onLogin = (e) =>{
        let path = `login`; 
        navigate(path);
    };

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`formuseradd wpo-contact-area section-padding ${props.contactclass}`} id="formuseradd">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="row mt-4">
                        <div className="co-lg-12   col-12 mt-4">
                            <div className="row mt-4">
                                <div className="col col-lg-12 col-md-12 col-12">
                                    <div className="wpo-contact-form">
                                        <form id="addrrms"> 
                                            <div className='row'>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Full Name <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.name}
                                                            type="text"
                                                            name="name"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Gender <span>*</span></label>
                                                        <select name="gender" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Email <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.email}
                                                            type="email"
                                                            name="email"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Mobile No <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.mobile}
                                                            inputProps={{ maxLength: 10 }}
                                                            type="text"
                                                            name="mobile"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>CMS ID<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.cms_id}
                                                            type="text"
                                                            name="cms_id"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Division<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.division}
                                                            type="text"
                                                            name="division"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Category<span>*</span></label>
                                                        <select name="category" required style={{color:'Black'}} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Category</option>
                                                            {categorylist.map((option) => (
                                                                <option key={option.id} value={option.category}>
                                                                    {option.category}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-3 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Password<span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.cpassword}
                                                            type="password"
                                                            name="cpassword"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)} disabled={isButtonDisabled}>
                                                    Submit Now
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
            <Scrollbar/>
        </>
    )
}
export default UserAdd;